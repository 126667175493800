import { resolveComponent as _resolveComponent, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "oilcase" }
const _hoisted_2 = { class: "oilcase__container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_cmp = _resolveComponent("header-cmp")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_footer_cmp = _resolveComponent("footer-cmp")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createVNode(_component_header_cmp),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_Transition, { name: "page" }, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_footer_cmp)
  ]))
}