import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "oilcase-block-clients" }
const _hoisted_2 = { class: "oilcase-block-clients__block-text" }
const _hoisted_3 = { class: "oilcase-block-clients__block-text__title" }
const _hoisted_4 = { class: "oilcase-block-clients__block-text__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default"),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.list_title), 1),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.list_text), 1)
    ])
  ]))
}