import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "oilcase-info-block__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "oilcase-info-block",
    style: _normalizeStyle({borderColor: _ctx.frame_color})
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default"),
      _createElementVNode("span", {
        style: _normalizeStyle({color: _ctx.frame_color})
      }, _toDisplayString(_ctx.info_title), 5)
    ]),
    _createElementVNode("span", null, _toDisplayString(_ctx.info_text), 1)
  ], 4))
}