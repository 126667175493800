import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "oilcase-menubar" }
const _hoisted_2 = { class: "oilcase-menubar__menu" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menubar, (menu, idx) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "oilcase-menubar__menu__container",
          key: menu.id
        }, [
          _createElementVNode("span", {
            class: _normalizeClass({_active: menu.id === _ctx.active_menubar.id}),
            onClick: ($event: any) => (_ctx.selectMenu(menu.id))
          }, _toDisplayString(menu.text), 11, _hoisted_3),
          idx
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass([{'_slide-right': _ctx.active_menubar.id === 1 }, "oilcase-menubar__menu__container__line"])
              }, null, 2))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ])
  ]))
}